import React from 'react';
import { API } from '../api/APIClient'
import Axios from 'axios'
import { Link } from 'react-router-dom';




class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dashData : undefined,
            modalOpen: false,
            modalTitle: "",
            modalMessage: "",
            modalButtons: [],
            isMenuOpen: false
        };

     }

    componentDidMount()
    {
        this.getDashInfo();
        // Set up an interval to call getDashInfo every 60 seconds (60000 ms)
        this.interval = setInterval(() => {
            this.getDashInfo();
        }, 60000);
    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    toggleMenu = () => {
        this.setState((prevState) => ({
            isMenuOpen: !prevState.isMenuOpen // Toggle menu visibility
        }));
    }


    getDashInfo()
    {
        Axios
            .get(API.user.getDashData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        dashData: res.data.data

                    })
                }
            }).catch((error) => {
             if (error.response) {
                 if (error.response.status === 500) {
                     this.handleError('Server Error: 500. Please try again later.');
                } else {
                     this.handleError(`Error: ${error.response.status} - ${error.response.data.message || 'An error occurred.'}`);
                }
            } else if (error.request) {
                 this.handleError('Network error: No response received. Please check your connection.');
            } else {
                 this.handleError(`Request error: ${error.message}`);
            }
        });
    }


    handleError(errorMessage) {
        this.setState({
            modalOpen: true,
            modalTitle: "error",
            modalMessage: errorMessage,
            modalButtons: [{ label: "OK", onClick: () => this.dismissAlertModal() }]
        });
    }


    dismissAlertModal() {
        this.setState({
            modalOpen: false
        });
    }

    render() {
        const { isMenuOpen } = this.state;

        return (
            <div className="dashboard">
                <header className="header">
                    <div className="menu-icon" onClick={this.toggleMenu}>
                        {/* Menu icon (hamburger) */}
                        <i className="fas fa-bars"></i>
                    </div>

                    <div className="big-logo"></div>

                    <div className="logout-icon" onClick={this.props.logoutHandler}>
                        {/* Logout icon */}
                        <i className="fas fa-sign-out-alt"></i>
                    </div>
                </header>

                {isMenuOpen && (
                    <nav className="menu">
                        <div className="menu-link"><Link to="/card">Gas Card Review</Link></div>
                        <div className="menu-link"><Link to="/horror">Horror Show Approvals</Link></div>
                        <div className="menu-link"><Link to="/talent">Talent Show Approvals</Link></div>
                        <div className="menu-link"><Link to="/issues">App Issues Submitted</Link></div>
                    </nav>
                )}




                {this.state.dashData ? (
                    <div className="dashboard-content">


                        <Link to="/issues"><div className="dashboard-section">
                            {this.state.dashData.issuesWaiting > 0 && (
                                <span className="red-dot"></span>
                            )}
                            <h1>App Issues:</h1>
                            <div className="stat-item">Unresolved issues: {this.state.dashData.issuesWaiting}</div>
                        </div></Link>


                        <Link to="/card"><div className="dashboard-section">
                            {this.state.dashData.cardsWaiting > 0 && (
                                <span className="red-dot"></span>
                            )}
                            <h1>Gas Cards:</h1>

                            <div className="stat-item">Awaiting approval: {this.state.dashData.cardsWaiting}</div>
                        </div></Link>

                        <Link to="/horror"><div className="dashboard-section">
                            {this.state.dashData.horrorWaiting > 0 && (
                                <span className="red-dot"></span>
                            )}
                            <h1>Horror Show:</h1>
                            <div className="stat-item">Awaiting approval: {this.state.dashData.horrorWaiting}</div>
                        </div></Link>

                        <Link to="/talent"><div className="dashboard-section">
                            {this.state.dashData.talentWaiting > 0 && (
                                <span className="red-dot"></span>
                            )}
                            <h1>Talent Show:</h1>
                            <div className="stat-item">Awaiting approval: {this.state.dashData.talentWaiting}</div>
                        </div></Link>



                        <div className="dashboard-section">
                            <h1>Paid users:</h1>
                            <div className="stat-item">iOS: {this.state.dashData.subscription_totals.ios} Android: {this.state.dashData.subscription_totals.android } Total: {this.state.dashData.subscription_totals.ios + this.state.dashData.subscription_totals.android}</div>
                            <div className="stat-item">Today - Gained {this.state.dashData.gain }, Lost {this.state.dashData.loss }</div>

                        </div>
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        );
    }

}

export default Dashboard;
